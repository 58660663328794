import React from "react";

import PinEnabled from "../../assets/pin-enabled.svg"
import PinDisabled from "../../assets/pin-disabled.svg"
import VideoView from './videoView';
import { IoMicOff } from "react-icons/io5";
import conferenceStore from "./store";

const PinnedScreen = ({streams,pinScreen,localMediaval,localScreenval,setPinScreen,}) =>{
    const {activeMicList, isMicOn, isCameraOn, share, userG, } = conferenceStore(state => state);

    return(
        <div style={{ flexDirection: "row", width: "100%", display: "flex", justifyContent: "space-between", }}>
        <div style={{ width: "70%", }}>
            {pinScreen == -1 && share && <div style={{ display: "flex", height: "100%", width: "calc(100% - 10px)", position: "relative", borderRadius: 10, backgroundColor: "#636363" }} onClick={() => { }}>
                <VideoView key="localvideo1" stream={localScreenval} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} objectFit={"contain"} type={"screenType"} />
                <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 1, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                    {userG?.name} is presenting
                </div>
                <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.5)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(0); }}>
                    <img alt="" style={{ height: 20, width: 20 }} src={PinDisabled} />
                </button>
            </div>}
            {pinScreen == -2 && <div style={{ display: "flex", height: "100%", width: "calc(100% - 10px)", position: "relative", borderRadius: 10, backgroundColor: "#636363" }} onClick={() => { }}>
                {localMediaval && isCameraOn? <VideoView key="localvideo" stream={localMediaval} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={"videoType"} objectFit={"cover"}  />:
                 <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}> {userG?.profileImage ? <img alt="" src={userG?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : userG?.name?.[0]}</div>}
                <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 1, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                You
                </div>
                <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.5)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(0); }}>
                    <img alt="" style={{ height: 20, width: 20 }} src={PinDisabled} />
                </button>
                { 
( !isMicOn)&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
            
<IoMicOff style={{ height: 20, width: 20, color: "white" }} />


                 </div>}
            </div>}

            {streams.filter(ele => ((ele?.user?.id && ele?.user?.id === pinScreen?.id) || ele?.id === pinScreen?.id) && (ele?.type === pinScreen?.type)).map((stream, index) => {
              

                return (
                    <div key={index} style={{ position: "relative", borderRadius: 10, gap: 5, height: `100%`, width: "calc(100% - 10px)", backgroundColor: "#636363" }} >
                        {stream?.mediaStream && <VideoView stream={stream?.mediaStream} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={stream?.type} objectFit={stream?.type==="screenType"?"contain":"cover"}  />}
                        {<div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}>{stream?.profileImage || stream?.user?.profileImage ? <img alt="" src={stream?.profileImage || stream?.user?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : stream?.name?.[0] || stream?.user?.name?.[0]} </div>}
                        {/* <div style={{ color: "white" }} > {stream?.user?.name}</div> */}
                        <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                            {stream?.user?.name || stream?.name} {stream?.type === "screenType" ? " is presenting" : ""}
                        </div>
                        <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.5)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(0); }}>
                            <img alt="" style={{ height: 20, width: 20 }} src={PinDisabled} />
                        </button>
                        { 
( !activeMicList[stream?.user?.id])&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
            
<IoMicOff style={{ height: 20, width: 20, color: "white" }} />


                 </div>}
                    </div>
                )
            })}
        </div>
        <div style={{ flexDirection: "column", width: "28%", overflowY: "scroll", height: "100%" }}>
            {pinScreen !== -1 && share && <div style={{ display: "flex", height: `28%`, width: "90%", position: "relative", borderRadius: 10, backgroundColor: "#636363" }} onClick={() => { setPinScreen(-1);  }}>
                <VideoView key="localvideo1" stream={localScreenval} style={{ height: "100%", width: "100%", borderRadius: 10,padding:2 }} visible={true} type={"screenType"} />
                <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 1,width:"75%" }}>
                    {userG?.name} is presenting
                </div>
                <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(-1); }}>
                    <img alt="" style={{ height: 20, width: 20 }} src={PinEnabled} />
                </button>
            </div>}
            {(pinScreen !== -2 ) && <div style={{ display: "flex", height: `28%`, width: "90%", position: "relative", borderRadius: 10, backgroundColor: "#636363" }} onClick={() => { setPinScreen(-1);  }}>
                {localMediaval&& isCameraOn?<VideoView key="localvideo" stream={localMediaval} style={{ height: "100%", width: "100%", borderRadius: 10,padding:2 }} visible={true} type={"videoType"} />
               : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}> {userG?.profileImage ? <img alt="" src={userG?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : userG?.name?.[0]}</div>}
                <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 1, width:"75%"}}>
                You
                </div>
                <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(-2); }}>
                    <img alt="" style={{ height: 20, width: 20 }} src={PinEnabled} />
                </button>
                { 
( !isMicOn)&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
            
<IoMicOff style={{ height: 20, width: 20, color: "white" }} />


                 </div>}
            </div>}
            {streams
                .filter(ele => !(((ele?.user?.id && ele?.user?.id === pinScreen?.id) || ele?.id === pinScreen?.id) && ele?.type === pinScreen?.type))
                .map((stream, index) => {
                   
                    if (stream?.mediaStream) {
                        if (stream?.user?.id !== pinScreen?.id || (stream?.user?.id === pinScreen?.id && stream?.type !== pinScreen?.type)) {
                            return (
                                <div key={index} style={{ position: "relative", marginTop: 8, height: "28%", width: "90%", backgroundColor: "#636363", borderRadius: 10 }} >
                                    <VideoView stream={stream?.mediaStream} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={stream?.type}  />
                                    {<div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}>{stream?.user?.profileImage ? <img alt="" src={stream?.user?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : stream?.name?.[0] || stream?.user?.name?.[0]} </div>}
                                    {/* <div style={{ color: "white" }} > {stream?.user?.name}</div> */}
                                    <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold",width:"75%" }}>
                                        {`${stream?.user?.name} ${stream?.type === "screenType" ? " is presenting" : ""}`}
                                    </div>
                                    <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen({ id: stream?.user?.id, type: stream?.type }); }}>
                                        <img alt="" style={{ height: 20, width: 20 }} src={PinEnabled} />
                                    </button>
                                    { 
( !activeMicList[stream?.user?.id])&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
            
<IoMicOff style={{ height: 20, width: 20, color: "white" }} />


                 </div>}
                                    {/* ( micListed[item?.id])?
<FaMicrophone style={{ height: 15, width: 15, color: "white" }} />
:
<IoMicOff style={{ height: 20, width: 20, color: "white" }} /> */}
                                </div>
                            )
                        }
                        else {
                            return ""
                        }
                    }
                    else {
                        if (stream?.id) {
                            return (
                                <div style={{ position: "relative", marginTop: 8, height: `28%`, width: "90%", borderWidth: 1, borderColor: "red", backgroundColor: "#636363", color: "white", borderRadius: 5 }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "80%", color: "white" }}>{stream?.profileImage ? <img alt="" src={stream?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : stream?.name?.[0]}</div>
                                    <div style={{ marginLeft: 5 }}> {stream?.name}</div>
                                    <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen({ id: stream?.id, type: undefined }); }}>
                                        <img style={{ height: 20, width: 20 }} src={PinEnabled} />
                                    </button>
                                </div>
                            )
                        }
                        else {
                            return ""
                        }
                    }
                })}
        </div>
    </div>   
    )

}
export default PinnedScreen