import React from "react";

import PinEnabled from "../../assets/pin-enabled.svg"
import VideoView from './videoView';
import { IoMicOff } from "react-icons/io5";
import conferenceStore from "./store";
const widthFnc = (arr) => {

    let width;
    if (arr === 1) {
        width = '91%';
    } else if (arr >= 2 && arr <= 4) {
        width = arr===2?'46%':'38%';
    } else if (arr > 4 && arr < 10) {
        width ="46%";
    }
    else if (arr >= 10 && arr <= 12) {
        width = '22%';
    }
    else if (arr > 12 && arr < 16) {
        width = '18%';
    }
    else if (arr > 16 && arr < 26) {
        width = '15%';
    }
    else {
        width = 'auto'; // Or any other default value
    }

    return width
}
const heightFnc = (arr) => {

    let height;
    if (arr <= 1) {
        height = '91%';
    } else if (arr > 2 && arr <= 6) {
        height = '47%';
    } else if (arr > 6 && arr < 16) {
        height = '29%';
    }
    else if (arr > 16 && arr < 24) {
        height = '22%';
    }
    //    else if (arr >= 10 && arr <= 12) {
    //     height = '24%';
    //  }
    //    else if (arr >12 && arr <16){
    //       height = '19%';
    //   }
    else {
        height = 'auto'; // Or any other default value
    }

    return height
}
const UnpinnedScreens = ({streams,setPinScreen,localMediaval,localScreenval}) =>{
    const {activeMicList, isMicOn, isCameraOn, share, userG, } = conferenceStore(state => state);

    return(
        <div style={{ flexDirection: "row", width: "100%", display: "flex", justifyContent: "center", flexWrap: "wrap",gap:40  }}>
        {share && <div style={{ display: "flex", height: heightFnc(share ? streams?.length + 2 : streams?.length+1), width: widthFnc(share ? streams?.length + 2 : streams?.length+1), borderRadius: 10, position: "relative", backgroundColor: "#636363" }} onClick={() => { }}>
               <VideoView key="localvideo1" stream={localScreenval} style={{ height: "100%", width: "100%", borderRadius: 10,padding:2 }} visible={true} objectFit={"contain"}/>
               <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                   You are presenting
               </div>
               <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(-1); }}>
                   <img alt="" style={{ height: 20, width: 20 }} src={PinEnabled} />
               </button>
           </div>}
           
           <div style={{ display: "flex", height: heightFnc(share ? streams?.length + 2 : streams?.length+1), width: widthFnc(share ? streams?.length + 2 : streams?.length+1), borderRadius: 10, position: "relative", backgroundColor: "#636363" }} onClick={() => { }}>
           {localMediaval && isCameraOn? <VideoView key="localvideo" stream={localMediaval} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={"videoType"} />:
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}> {userG?.profileImage ? <img alt="" src={userG?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }} /> : userG?.name?.[0]}</div>}
               <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold" }}>
                  You
               </div>
               <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setPinScreen(-2); }}>
                   <img alt="" style={{ height: 20, width: 20 }} src={PinEnabled} />
               </button>
               {( !isMicOn)&&
               <div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5,}}>
               

               <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
   

                    </div>}
           </div>


          
           {streams.map((stream, index) => {

               if (stream.mediaStream) {
                   return (
                       <div key={index} style={{ position: "relative", gap: 0, height: heightFnc(share ? streams?.length + 2 : streams?.length+1), width: widthFnc(share ? streams?.length + 2 : streams?.length+1), backgroundColor: "#636363",borderRadius:10 }} >
                           {/* <VideoView stream={stream?.mediaStream} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} /> */}
                        
                           {stream?.mediaStream && <VideoView stream={stream?.mediaStream} style={{ height: "100%", width: "100%", borderRadius: 10 }} visible={true} type={stream?.type} objectFit={stream?.type==="screenType"?"contain":"cover"} />}
                           <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "100%", color: "white" }}> {stream?.user?.profileImage ? <img alt="" src={stream?.user?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }}  /> : stream?.name?.[0] || stream?.user?.name?.[0]}</div>
                           {/* <div style={{ color: "white" }} > {stream?.user?.name}</div> */}
                           <div style={{ position: "absolute", top: 10, left: 10, color: "white", zIndex: 5, textShadow: "2px 2px 2px grey", fontWeight: "bold",width:"75%" }}>
                               {stream?.user?.name} {stream?.type === "screenType" ? " is presenting" : ""}
                           </div>
                           {/* {(!share && streams?.length === 1) ? "" : */}
                            <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => {
                               // if (!share && streams?.length === 1) {
                               //     return
                               // }
                               e.stopPropagation(); setPinScreen({ id: stream?.user?.id, type: stream?.type });
                           }}>
                               <img alt="" style={{ height: 20, width: 20 }} src={PinEnabled} />
                           </button>
                           { 
( !activeMicList[stream?.user?.id])&&<div style={{ position: "absolute", top: 10, right: 50, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }}>
               
   <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
   

                    </div>}
                           {/* { 
( micListed[stream?.user?.id])?
   <FaMicrophone style={{ height: 15, width: 15, color: "white" }} />
   :
   <IoMicOff style={{ height: 20, width: 20, color: "white" }} />
} */}
                      
                       </div>
                   )
               }
               else {
                   return (
                       <div style={{ position: "relative", height: heightFnc(share ? streams?.length + 2 : streams?.length+1), width: widthFnc(share ? streams?.length + 2 : streams?.length+1), borderWidth: 1, borderColor: "red", backgroundColor: "#636363", color: "white", borderRadius: 5, display: "flex", }}>
                           <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 600, fontSize: 40, width: "100%", height: "90%", color: "white" }}> {stream?.profileImage ? <img src={stream?.profileImage} style={{ height: 60, width: 60, borderRadius: 30, border: "solid 2px white" }}  /> : stream?.name?.[0]}</div>
                           <div style={{ position: "absolute", top: 10, left: 10, marginLeft: 5, }}> {stream?.name}</div>
                           {/* {(!share && streams?.length === 1) ? "" :  */}
                           
                           <button style={{ position: "absolute", top: 10, right: 10, padding: 5, background: "rgba(0, 0, 0, 0.3)", color: "white", border: "none", borderRadius: 5, cursor: "pointer" }} onClick={(e) => {
                               // if (!share && streams?.length === 1) {
                               //     return
                               // }
                               e.stopPropagation(); setPinScreen({ id: stream?.id, type: undefined });
                           }}>
                               {<img style={{ height: 20, width: 20 }} src={PinEnabled} />}
                           </button>
                        
                           {/* } */}
                       </div>
                   )
               }
           })}




       </div> 
    )

}
export default UnpinnedScreens